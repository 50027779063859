import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Toast, Dialog } from "vant";
import i18n from "@/i18n";
import config from "@/config";

//接口配置
var ApiUrl = config.ApiUrl;

var Language = config.Language;

localStorage["CurrLine"] = ApiUrl;

//判断是否是安卓用户
var phoneModel = "";
var phonePlatform = window.navigator.platform;

// console.log(12312,window.navigator.platform.indexOf('Linux'))
// var user_id = window.cookie.getItem('user_id');
// var user_token = window.cookie.getItem('user_token');
// phoneModel = 'X1VUKlhPdauGqXm7'

function getCookie(name) {
  var cookie = document.cookie.split(";");
  for (let i = 0; i < cookie.length; i++) {
    var value = cookie[i].split("=");
    if (value[0].indexOf(name) == 0) {
      return value[1];
    }
    return "";
  }
}

var user_id = getCookie(user_id);
var user_token = getCookie(user_token);

var instance = axios.create({
  baseURL: (localStorage["CurrLine"] || ApiUrl) + "/api/",
  headers: {
    // "Cache-Control":"no-cache",
    //   "X-Forwarded-For": "127.0.0.1",
    user_id: user_id,
    user_token: user_token,
  },
});

/*取消请求*/
var source = axios.CancelToken.source();

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    let url = config.url.substr(0, 18);
    //这个接口地址没有api/
    let header = (localStorage["UploadApi"] || localStorage["CurrLine"]).substr(
      0,
      18
    );
    if (url == header) {
      config.baseURL = header;
    } else{
        config.baseURL = ApiUrl + "/api/";
    }
    config.cancelToken = source.token;
    config.data = config.data || {};
    config.data.lang = localStorage["Language"] || Language;
    if (!config.noLogin) {
      config.data.token = localStorage["Token"];
    }
    // if(config.url=="Transaction/getDrawtype"||config.url=="Account/changeBankCardInfo"){
    // 	config.baseURL="https://dzb129.com/api/"
    // 	config.data.token="ec78LMFwhYFxQgVZWcbAfvLIW0Epq7w3i1pa3/SgrviUtlF2TfvagED+ew"
    // 	config.data.uid=177494
    // }
    /* if(!config.fromData){
    config.data = $.param(config.data);
  } */
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    // return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    //如果303表示只是手机才能访问
    if (response.data.code == 303) {
      return false;
    }

    var Api = response.request.responseURL.slice(
      response.request.responseURL.lastIndexOf("/")
    );
    if (response.data.code > 200 && response.data.code < 206) {
      localStorage.removeItem("Token");
      localStorage.removeItem("UserInfo");
      localStorage.removeItem("BankCardList");
      store.dispatch("UpdateUserInfo", "");
      store.dispatch("UpdateBankCardList", []);
      Toast.clear();
      if (router.history.current.name == "vip") {
        return response;
      }
      source.cancel();
      Dialog.alert({
        title: i18n.t("dialog[0]"),
        message: response.data.code_dec,
        confirmButtonText:i18n.t("dialog[1]"),
        closeOnPopstate: true,
      })
        .then(() => {
          router.push("/login");
          source = axios.CancelToken.source();
        })
        .catch(() => {
          router.push("/login");
          source = axios.CancelToken.source();
        });
    }
    return response;
  },
  (error) => {
    /*  console.log(error);
    // 对响应错误做点什么
    const err = /<h1>(.*?)<\/h1>/ig.exec(error.response.data)[1]; */
    // Toast({ message: "请求响应错误" });
    return {
      data: -1,
    };
    // const config = error.config;
    // if (!config || !config.retry) return Promise.reject(error);
    // config.__retryCount = config.__retryCount || 0;
    // if (config.__retryCount >= config.retry) {
    //     return Promise.reject(error);
    // }
    // config.__retryCount += 1;
    // const backoff = new Promise(function(resolve) {
    //   setTimeout(function() {
    //       resolve();
    //   }, 2000);
    // });
    // return backoff.then(function() {
    //   return axios(config);
    // });
  }
);

export default instance;
